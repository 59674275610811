.block-link {
  border-radius: 10px;
  background-color: $color-signal-white;
  border: 0.5px solid $color-gray-80;
  height: 65px;
  width: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 5px;
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
}
.block-link__description {
  @include body-two;
}
.block-link-title {
  @include body-one;
  max-width: 110px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @supports (-webkit-line-clamp: 2) {
    white-space: initial;
    max-width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  &:only-child {
    @supports (-webkit-line-clamp: 3) {
      -webkit-line-clamp: 3;
    }
  }
}
