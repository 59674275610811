.header-container{
  background-color: $color-white;
  height: 65px;
  box-sizing: border-box;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $color-gray-80;
  justify-content: space-between;
  align-items: center;
  .logo-container{
    height: 28px;
    img{height: 100%}
  }
  & > .bp3-button{
    margin-left: auto;
  }
  .header-expand-side{
    display: none;
  }
  .avatar-label{
    cursor: pointer;
  }
  @include for-size(phone-only){
    .header-expand-side{
      display: block;
    }
    .avatar-label{
      border: 0;
      min-width: 0;
      display: block;
      min-height: 0;
      padding: 0;
      & > span{
        display: none;
      }
    }
  }
}
