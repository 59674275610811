.package-icons-container {
  padding: 1rem;
  background-color: $color-faux-ghost-white;
}

.icons-col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
  gap: 10px;
  &:hover {
    background-color: $color-misty-rose;
  }
}
