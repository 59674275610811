.teams-content {
  display: flex;

  .teams-side-menu {
    margin-right: 15px;
  }

  .teams-table-list{

    width: 100%;
    display: flex;
    flex-direction: column;

    .teams-table-header {
      background-color: $color-signal-white;
      border: none;
      display: flex;
      align-items: center;
      border-radius: 5px;
      padding-left: 18px;
      justify-content: space-around;
      @include list-header;
      height: 42px;

    }
    .teams-table-body {
      display: flex;
      justify-content: space-around;
      border: 0.5px solid rgba($color-gray-80, 0.5);
      margin-top: 10px;
      border-radius: 5px;
      padding-left: 18px;

      @include for-size(desktop-up){
        height: 60px;

      }
      & .td_button_text_decoration_color {
        text-decoration: underline;
        & .bp3-button-text{
          font-weight: 600;

        }
      }
    }
  }

}

.priority-remove {
  display: flex;
  flex-direction: column;
  gap: 10px;
  // width: 70%;

  & span:first-child {
    @include body-one;
    line-height: 1.5rem;
  }
  .priority-remove-label {
    width: fit-content;
    margin-top: 9px;
    height: 36px;
  }
}

.edit-priorty-container {
  display: flex;
  margin: 21px 35px;
  align-items: unset;
  gap: 10px;
  flex-direction: column;
  & span:first-child,
  .priority-sub-title {
    @include body-one;
  }
  & .edit-add-priority-details {
    display: flex;
    gap: 5px 150px;
  }
  .priority-radio-group {
    .radio-group-container {
      label {
        border: unset;
      }
    }
  }

  .assign-priority-members-section {
    display: flex;
    gap: 40px;
    .add-teams-fab-container {
      display: flex;
      flex-direction: column;
      .add-teams-fab-content {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
      }
    }
  }
}

