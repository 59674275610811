.filters-bar-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 10px;
  overflow: hidden;
  & > .filters-bar-children {
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    & > * {
      margin-right: 10px;
    }
    & > :last-child {
      width: fit-content;
      margin-right: 0;
    }
  }
  @include for-size(desktop-down) {
    .filters-bar-children {
      margin-left: 0;
    }
  }
}
