//TODO: refactor and remove extras
@mixin expandable-card {
  display: block;
  border: 0.5px solid $color-gray-80;
  color: $color-dark-charcoal;
  background-color: $color-white;
  border-radius: 5px;
  padding-top: 18px;
  padding-bottom: 18px;
  margin-bottom: 16px;
  &:last-of-type {
    margin-bottom: 0px;
  }
  @include h-2;

  & > .card-main-data {
    display: flex;
    & > .cell {
      flex: 1 1 auto;
      display: flex;
      gap: 4px;
      align-items: center;
      justify-content: flex-start;
      padding-right: 20px;
      padding-left: 20px;
      // max-width: 200px;
      &:first-of-type {
        justify-content: flex-start;
        max-width: 160px;
      }
      & > .expand-button {
        display: flex;
        justify-content: center;
        align-items: center;
        background: none;
        border: none;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        &:hover {
          // background: $color-gray-80;
          cursor: pointer;
        }
      }
    }
  }
  & > .card-more-data {
    padding-right: 48px;
    padding-left: 48px;
  }
  &.expanded {
    background: $color-faux-ghost-white;
    & > .card-main-data {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    & > .card-more-data {
      margin-top: 18px;
      border-top: 0.5px solid $color-gray-80;
      padding-top: 18px;
      width: 100%;
    }
  }
  &.disabled {
    background-color: $color-gray-80;
  }
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f4f4f4;
  }

  &::-webkit-scrollbar-thumb {
    background: #cccccc;
    border-radius: 5px;

  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
