.dropdown-select {
  // width: 100%;
  position: relative;
  & label {
    position: absolute;
    top: -6px;
    left: 10px;
    z-index: 2;
    color: $color-quick-silver;
    background-color: $color-white;
    padding: 0px 5px;
    font-family: 'Source Sans Pro';
    font-size: 0.75em;
    font-style: normal;
    font-weight: 400;
    line-height: 0.9375em;
    letter-spacing: 0px;
    text-align: left;
    transition: visibility 0.1s ease-in-out;
    text-transform: capitalize;
  }
  & .selected-dropdown-highlight {
    font-family: 'Source Sans Pro' !important;
    font-size: 0.875rem !important;
    line-height: 1.125em !important;
    text-align: left !important;
    color: $color-dark-charcoal !important;
    font-weight: unset !important;
  }
  &:focus {
    outline: none;
  }

  .bp3-transition-container {
    & > .bp3-select-popover {
      min-width: 184px !important;
    }
  }
  .bp3-popover-wrapper.dropdown-select {
    &:focus {
      outline: none;
    }
  }
  .bp3-popover-target {
    &:focus {
      outline: none;
    }
    & > div:focus {
      outline: none;
      outline-offset: 0;
    }
  }
  .bp3-popover-open {
  }
  &.error {
    button {
      border-color: $color-alizarin !important;
    }
    span div .error-text {
      @include body-three;
      color: $color-quick-silver;
      padding-left: 10px;
      padding-top: 8px;
      color: $color-alizarin;
    }
  }

  & .selected-shifts-dropdown-highlight {
    font-family: 'Source Sans Pro' !important;
    font-size: 0.875rem !important;
    line-height: 1.125em !important;
    text-align: left !important;
    color: $color-dark-charcoal !important;
    font-weight: 600 !important;
  }
}

.dropdown-button {
  width: 184px;
  border: 1px solid $color-gray-80;
  background-color: $color-white !important;
  // color: $color-quick-silver !important;
  box-shadow: none !important;
  font-family: 'Source Sans Pro';
  font-size: 0.875em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125em;
  letter-spacing: 0;
  padding: 9px 15px 9px 15px;
  border-radius: 5px;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &.bp3-icon {
    color: $color-quick-silver !important;
  }
  & > .bp3-button-text {
    overflow: hidden !important;
  }

  &.morning {
    background-color: $color-tequila-orange !important;
    background-image: unset !important;
    border: none !important;
    outline: unset !important;
  }

  &.morning2 {
    background-color: $color-caramel !important;
    background-image: unset !important;
    border: none !important;
    outline: unset !important;
  }

  &.night {
    background-color: $color-onahau-blue !important;
    background-image: unset !important;
    border: none !important;
    outline: unset !important;
  }

  &.night2 {
    background-color: $color-pattens-blue !important;
    background-image: unset !important;
    border: none !important;
    outline: unset !important;
  }

  &.overnight {
    background-color: $color-alice-blue !important;
    background-image: unset !important;
    border: none !important;
    outline: unset !important;
  }

  &.overnight2 {
    background-color: $color-malibu-blue !important;
    background-image: unset !important;
    border: none !important;
    outline: unset !important;
  }

  &.video-status- {
    &planned {
      background-color: #e7e7e7 !important;
      background-image: unset !important;
      border: none !important;
      outline: unset !important;
    }
    &assigned,
    &recorded,
    &uploaded {
      background-color: #fff8cb !important;
      background-image: unset !important;
      border: none !important;
      outline: unset !important;
    }
    &completed,
    &recovered {
      background-color: #e2fee5 !important;
      background-image: unset !important;
      border: none !important;
      outline: unset !important;
    }
  }
  &.fit-content {
    min-width: fit-content;
  }
  &.blocked {
    border: 1px solid #dc2228 !important;
  }
}
.video-match-status-label {
  max-width: 160px;
  &:hover {
    cursor: default;
  }
  & > .bp3-icon {
    display: none;
  }
}
.bp3-menu {
  & > .users-variant-multi-select {
    & > .bp3-control.bp3-inline {
      display: flex;
      gap: unset;
    }
  }
}
