.scrollable-container{
  @include scrollbar;
  position: relative;
  overflow: hidden;
  .children{
    display: flex;
    gap: 40px;
    max-width: 80vw;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    // border: 1px solid red;
    max-height: 4vh;
    &::-webkit-scrollbar {
      height: 0;
      background: transparent; /* make scrollbar transparent */
    }
  }
  .scroll-button{
    position: absolute;
    top: 0px;
    &.right{
      right: 10px;
    }
    &.left{
      left: 10px;
    }
    border-radius: 50%;
  }
}
