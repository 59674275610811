*,
body {
  font-family: 'Source Sans Pro', sans-serif;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
}
.flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex.column {
  display: flex;
  flex-direction: column;
}

.automarginLeft {
  margin-left: auto;
}
.automarginRight {
  margin-right: auto;
}
.justify-center {
  justify-content: center;
}
.items-center {
  align-items: center;
}
.items-right {
  align-items: right;
}
.items-baseline {
  align-items: baseline;
}
.space-between {
  justify-content: space-between;
}
.space-around {
  justify-content: space-around;
}
.items-flex-start {
  align-items: flex-start;
}
.justify-end {
  justify-content: flex-end;
}

.selected-input-highlight {
  input {
    font-family: 'Source Sans Pro' !important;
    font-size: 0.875rem !important;
    line-height: 1.125em !important;
    text-align: left !important;
    color: $color-dark-charcoal !important;
  }
}

.modal-title {
  display: flex;
  align-items: center;
  gap: 3px;
  @include h-2;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.morning {
  background-color: $color-tequila-orange;
  & > .accent {
    background-color: $color-vivid-orange;
  }
}

.morning2 {
  background-color: $color-caramel;
  & > .accent {
    background-color: $color-dark-orange;
  }
}

.night {
  background-color: $color-onahau-blue;
  & > .accent {
    background-color: $color-dodger-blue;
  }
}

.night2 {
  background-color: $color-pattens-blue;
  & > .accent {
    background-color: $color-navy-blue;
  }
}

.overnight {
  background-color: $color-alice-blue;
  & > .accent {
    background-color: $color-anakiwa-blue;
  }
}

.overnight2 {
  background-color: $color-malibu-blue;
  & > .accent {
    background-color: $color-endeavour-blue;
  }
}

.modal-text {
  @include body-one;
}
.overflow-hidden {
  overflow: hidden !important;
}
.color-black {
  color: $color-black !important;
}

.info-text {
  @include body-three;
  color: $color-quick-silver;
}

.error-text {
  @include body-three;
  padding-left: 10px;
  padding-top: 8px;
  color: $color-alizarin !important;
}

.full-screen-error {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 80%;
  width: 100%;
  gap: 30px;
  flex-direction: column;
  & > span {
    @include h-2;
    color: $color-alizarin;
  }
}

.bp3-popover-content {
  @include scrollbar;
}

.body-one-txt {
  @include body-one;
}
.body-two-txt {
  @include body-two;
}
.body-three-txt {
  @include body-three;
}
.body-four-txt {
  @include body-four;
}
.shift-title-txt {
  @include shift-title;
}
.color-qs {
  color: $color-quick-silver !important;
}
.color-alizarin {
  color: $color-alizarin !important;
}
.color-sb {
  color: $color-science-blue !important;
}

.color-pig-green {
  color: $color-pigment-green !important;
}

.fs-12 {
  font-size: 12px;
  line-height: 15px;
}
.txt-right {
  text-align: right;
}
.txt-center {
  text-align: center !important;
}
.hide-text-decoration-minimal-button {
  &.bp3-button.bp3-minimal {
    text-decoration: unset !important;
  }
}
.btn-p-0 {
  &.bp3-button {
    padding: 0 !important;
  }
}
.btn-no-active-bg {
  &.bp3-active {
    background: none !important;
  }
}

.bp3-menu .bp3-menu-item {
  align-items: center !important;
}

.scrollableDiv {
  @include scrollbar;
}
.block {
  display: block;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.text-center {
  text-align: center;
}
.shift-match {
  margin-top: 13px;
  margin-bottom: 13px;
  padding-left: 32px;
  padding-right: 32px;
}

.filter-button {
  background-color: $color-white !important;
  box-shadow: unset !important;
  width: 46px !important;
  border: 1px solid $color-gray-80 !important;
  height: 35px !important;
  &.active {
    border-color: $color-science-blue !important;
    svg {
      fill: $color-science-blue !important;
    }
  }
}

.calender-icon {
  background-color: $color-white !important;
  box-shadow: unset !important;
  width: 46px !important;
  border: 1px solid $color-gray-80 !important;
  height: 35px !important;
}

.color-pigment-green {
  color: $color-Pigment-Green;
}

.error-search-box {
  & > .search-input-container {
    border-color: $color-alizarin !important;
  }
}

.sticky-header {
  position: sticky;
  top: 0;
  background-color: $color-white;
  width: 100%;
  z-index: 2;
}

.timestamp-info-container {
  padding-top: 15px;
  @include list-header;
  font-style: italic;
}
.time-stamp-container {
  display: flex;
  flex-direction: column;
  padding-left: 13px;
  & > .horizontal-label.timeStamp span {
    font-style: italic;
    font-weight: 400;
    font-size: 0.875rem;
    color: $color-quick-silver;
  }

  & > div .time-picker-container .rmdp-container > .rmdp-input {
    width: 74px;
    height: 37px;
    padding: 10px;
  }
}

@include for-size(tablet-landscape-up) {
  .justify-end-tablet-ls-up {
    justify-content: flex-end;
  }
}
