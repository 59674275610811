.match-assignment-container {
  background-color: $color-body-bg;
  height: 100%;
  & .matches-and-progress-container {
    & .progress-view-container {
      height: 166px;
      background-color: $color-gray-99;
      border: 0.5px solid rgba($color-gray-80, 0.5);
      border-radius: 5px;
      padding: 15px 25px;
      display: flex;
      flex-direction: column;

      & > .progress-view-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      & .progress-bar-content {
        display: flex;
        border: 0.5px solid rgba($color-gray-80, 0.5);
        border-radius: 5px;
        margin-top: 15px;
        padding: 15px 12px;
        height: 80px;
        & > span:first-of-type {
          width: 11%;
        }
        & > div:first-of-type {
          width: 80%;
          & p:first-child{
              @include h-2;
          }
        }
        & div {
          justify-content: space-between;
        }
      }
    }

    & .match-view-container {
      background-color: $color-gray-99;
      min-height: 840px;
      height: 86vh;
      border: 0.5px solid rgba($color-gray-80, 0.5);
      border-radius: 5px;
      // margin-top: 16px;
      padding: 24px 15px;
      overflow-y: auto;
      @include scrollbar;
    }
  }

  & .squads-view-container {
    width: 100%;
    margin-left: 15px;
    background-color: $color-gray-99;
    border: 0.5px solid rgba($color-gray-80, 0.5);
    border-radius: 5px;
    min-height: 840px;
    height: 86vh;
    padding: 22px 40px;
  }
}

.assign-matches-upload-container{
  background-color: $color-signal-white;
  border: 0.5px dotted rgba($color-gray-80, 0.5);
  margin:20px;

  div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 150px;
    gap: 15px;
  }
}

.assign-matches-file-name{
  margin: 0px 20px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: red;
}

.calender-colored-power-incomplete{
color: $color-alizarin;
}

.calender-colored-power-complete{
color: $color-power;
}

.dragged-component{
  width: 30% !important;
  .match-card-container > .match-card-ops-info{
    margin: 9px 10px;
    justify-content:unset;
  }
}
