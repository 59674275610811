.text-area-container {
  position: relative;
  display: flex;
  flex-direction: column;
  & > textarea{
    font-family: 'Open Sans Pro';
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    color: $color-dark-charcoal;
    padding: 8px;
    border-radius: 5px;
    border-color: $color-quick-silver;
    &::placeholder{
      color: $color-quick-silver;
      font-style: italic;
    }
  }
  & > .max-length-indicator{
    margin-left: auto;
    color: $color-quick-silver;
  }
}