.assign-shift-container {
  display: flex;
  margin-top: 21px;
  margin-left: 39px;
  align-items: center;
  gap: 22px;
  & > span:first-of-type {
    @include body-one;
  }

  & .shift-type-select-container {
    width: 130px !important;
  }
}
