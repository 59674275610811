@for $i from 0 through 100 {
  .p-#{$i}{
    padding: $i+px !important;
  }
  .pt-#{$i}{
    padding-top: $i+px !important;
  }
  .pb-#{$i}{
    padding-bottom: $i+px !important;
  }
  .pl-#{$i}{
    padding-left: $i+px !important;
  }
  .pr-#{$i}{
    padding-right: $i+px !important;
  }
  .mg-#{$i}{
    margin: $i+px !important;
  }
  .mgt-#{$i}{
    margin-top: $i+px !important;
  }
  .mgb-#{$i}{
    margin-bottom: $i+px !important;
  }
  .mgl-#{$i}{
    margin-left: $i+px !important;
  }
  .mgr-#{$i}{
    margin-right: $i+px !important;
  }
  .gap-#{$i}{
    gap: $i+px;
  }
  .width-#{$i}{
    width: round(percentage($i/100));
  }
}
