.bg-bolt_dim {
  width: 15px;
  height: 14px;
  background: url('../../assets/icons_sprite.png') -105px -39px;
  display: inline-block;
}

.bg-warning2 {
  width: 21px;
  height: 18px;
  background: url('../../assets/icons_sprite.png') -45px -25px;
  display: inline-block;
}

.bg-warning {
  width: 22px;
  height: 18px;
  background: url('../../assets/icons_sprite.png') -1px -45px;
  display: inline-block;
}

.bg-videoStatus {
  width: 18px;
  height: 14px;
  background: url('../../assets/icons_sprite.png') -1px -105px;
  display: inline-block;
}

.bg-upload {
  width: 15px;
  height: 18px;
  background: url('../../assets/icons_sprite.png') -105px -1px;
  display: inline-block;
}

.bg-shiftrotation {
  width: 18px;
  height: 18px;
  background: url('../../assets/icons_sprite.png') -21px -45px;
  display: inline-block;
}

.bg-settings {
  width: 18px;
  height: 18px;
  background: url('../../assets/icons_sprite.png') -41px -45px;
  display: inline-block;
}

.bg-replaceMatch {
  width: 18px;
  height: 12px;
  background: url('../../assets/icons_sprite.png') -41px -105px;
  display: inline-block;
}

.bg-reassign {
  width: 14px;
  height: 16px;
  background: url('../../assets/icons_sprite.png') -105px -21px;
  display: inline-block;
}

.bg-move {
  width: 8px;
  height: 12px;
  background: url('../../assets/icons_sprite.png') -1px -121px;
  display: inline-block;
}

.bg-expandSideMenu {
  width: 14px;
  height: 10px;
  background: url('../../assets/icons_sprite.png') -105px -85px;
  display: inline-block;
}

.bg-collapseSideMenu {
  width: 13px;
  height: 12px;
  background: url('../../assets/icons_sprite.png') -105px -71px;
  display: inline-block;
}

.bg-clock {
  width: 18px;
  height: 18px;
  background: url('../../assets/icons_sprite.png') -65px -1px;
  display: inline-block;
}

.bg-calender {
  width: 16px;
  height: 18px;
  background: url('../../assets/icons_sprite.png') -81px -85px;
  display: inline-block;
}

.bg-blocked {
  width: 14px;
  height: 14px;
  background: url('../../assets/icons_sprite.png') -105px -55px;
  display: inline-block;
}

.bg-back {
  width: 16px;
  height: 16px;
  background: url('../../assets/icons_sprite.png') -122px -19px;
  display: inline-block;
}

.bg-arrange_ranks {
  width: 18px;
  height: 13px;
  background: url('../../assets/icons_sprite.png') -21px -105px;
  display: inline-block;
}

.bg-arrange {
  width: 16px;
  height: 12px;
  background: url('../../assets/icons_sprite.png') -122px -90px;
  display: inline-block;
}

.bg-success {
  width: 16px;
  height: 16px;
  background: url('../../assets/icons_sprite.png') -122px -37px;
  display: inline-block;
}

.bg-user {
  width: 18px;
  height: 18px;
  background: url('../../assets/icons_sprite.png') -65px -41px;
  display: inline-block;
}

.bg-team {
  width: 16px;
  height: 16px;
  background: url('../../assets/icons_sprite.png') -122px -55px;
  display: inline-block;
}

.bg-star {
  width: 16px;
  height: 15px;
  background: url('../../assets/icons_sprite.png') -122px -73px;
  display: inline-block;
}

.bg-profile {
  width: 18px;
  height: 18px;
  background: url('../../assets/icons_sprite.png') -1px -65px;
  display: inline-block;
}

.bg-more {
  width: 18px;
  height: 18px;
  background: url('../../assets/icons_sprite.png') -41px -65px;
  display: inline-block;
}

.bg-money {
  width: 18px;
  height: 18px;
  background: url('../../assets/icons_sprite.png') -61px -65px;
  display: inline-block;
}

.bg-menu {
  width: 14px;
  height: 10px;
  background: url('../../assets/icons_sprite.png') -83px -105px;
  display: inline-block;
}

.bg-groups {
  width: 18px;
  height: 18px;
  background: url('../../assets/icons_sprite.png') -85px -1px;
  display: inline-block;
}

.bg-graph {
  width: 18px;
  height: 18px;
  background: url('../../assets/icons_sprite.png') -85px -21px;
  display: inline-block;
}

.bg-flag {
  width: 18px;
  height: 22px;
  background: url('../../assets/icons_sprite.png') -45px -1px;
  display: inline-block;
}

.bg-cog {
  width: 18px;
  height: 18px;
  background: url('../../assets/icons_sprite.png') -85px -61px;
  display: inline-block;
}

.bg-cancel {
  width: 18px;
  height: 18px;
  background: url('../../assets/icons_sprite.png') -21px -85px;
  display: inline-block;
}

.bg-bolt {
  width: 18px;
  height: 18px;
  background: url('../../assets/icons_sprite.png') -41px -85px;
  display: inline-block;
}

.bg-cog-qs {
  width: 18px;
  height: 18px;
  background: url('../../assets/icons_sprite.png') -18px -118px;
  display: inline-block;
}
//TODO: Remove css_sprites.png
.bg-video {
  width: 18px;
  height: 16px;
  background: url('../../assets/css_sprites.png') -225px -124px;
  display: inline-block;
}

.bg-football {
  width: 21px;
  height: 20px;
  background: url('../../assets/users_sprite.png') -10px -10px;
}

.bg-resetPassword {
  width: 18px;
  height: 19px;
  background: url('../../assets/users_sprite.png') -49px -50px;
}

.bg-resetMfa {
  width: 19px;
  height: 19px;
  background: url('../../assets/users_sprite.png') -10px -50px;
}

.bg-deactivate {
  width: 18px;
  height: 18px;
  background: url('../../assets/users_sprite.png') -92px -10px;
}

.bg-amf {
  width: 21px;
  height: 20px;
  background: url('../../assets/users_sprite.png') -51px -10px;
}

.bg-edit {
  width: 18px;
  height: 18px;
  background: url('../../assets/users_sprite.png') -92px -48px;
}

.bg-delete {
  width: 18px;
  height: 18px;
  background: url('../../assets/users_sprite.png') -10px -89px;
}

.bg-remove {
  width: 18px;
  height: 18px;
  background: url('../../assets/users_sprite.png') -48px -89px;
}

.bg-reactivate {
  width: 18px;
  height: 18px;
  background: url('../../assets/users_sprite.png') -86px -89px;
}

.apple-tv {
  width: 25px;
  height: 25px;
  background: url('../../assets/providers_sprite.png') -65px -10px;
}

.bein-sport {
  width: 35px;
  height: 26px;
  background: url('../../assets/providers_sprite.png') -10px -10px;
}

.bg-acquisition {
  width: 19px;
  height: 19px;
  background: url('../../assets/menu_sprite.png') -10px -10px;
  display: inline-block;
}

.bg-active_acquisition {
  width: 19px;
  height: 19px;
  background: url('../../assets/menu_sprite.png') -49px -10px;
  display: inline-block;
}

.bg-active_bang {
  width: 18px;
  height: 18px;
  background: url('../../assets/menu_sprite.png') -10px -49px;
  display: inline-block;
}

.bg-active_calenderView {
  width: 16px;
  height: 12px;
  background: url('../../assets/menu_sprite.png') -44px -125px;
  display: inline-block;
}

.bg-active_check {
  width: 18px;
  height: 18px;
  background: url('../../assets/menu_sprite.png') -48px -49px;
  display: inline-block;
}

.bg-active_dashboard {
  width: 14px;
  height: 14px;
  background: url('../../assets/menu_sprite.png') -126px -78px;
  display: inline-block;
}

.bg-active_groups {
  width: 18px;
  height: 10px;
  background: url('../../assets/menu_sprite.png') -116px -125px;
  display: inline-block;
}

.bg-active_out {
  width: 16px;
  height: 14px;
  background: url('../../assets/menu_sprite.png') -126px -10px;
  display: inline-block;
}

.bg-active_pcAssignment {
  width: 18px;
  height: 18px;
  background: url('../../assets/menu_sprite.png') -88px -10px;
  display: inline-block;
}

.bg-active_user {
  width: 18px;
  height: 18px;
  background: url('../../assets/menu_sprite.png') -88px -48px;
  display: inline-block;
}

.bg-arrow_down {
  width: 11px;
  height: 8px;
  background: url('../../assets/menu_sprite.png') -162px -40px;
  display: inline-block;
}

.bg-bang {
  width: 18px;
  height: 18px;
  background: url('../../assets/menu_sprite.png') -10px -87px;
  display: inline-block;
}

.bg-calenderView {
  width: 16px;
  height: 12px;
  background: url('../../assets/menu_sprite.png') -80px -125px;
  display: inline-block;
}

.bg-check {
  width: 18px;
  height: 18px;
  background: url('../../assets/menu_sprite.png') -48px -87px;
  display: inline-block;
}

.bg-dashboard {
  width: 14px;
  height: 14px;
  background: url('../../assets/menu_sprite.png') -10px -125px;
  display: inline-block;
}

.bg-groups {
  width: 18px;
  height: 10px;
  background: url('../../assets/menu_sprite.png') -162px -10px;
  display: inline-block;
}

.bg-out {
  width: 16px;
  height: 14px;
  background: url('../../assets/menu_sprite.png') -126px -44px;
  display: inline-block;
}

.bg-pcAssignment {
  width: 18px;
  height: 18px;
  background: url('../../assets/menu_sprite.png') -86px -87px;
  display: inline-block;
}

.bg-video-status- {
  &completed,
  &recovered {
    width: 18px;
    height: 18px;
    background: url('../../assets/video_status_sprite.png') -5px -5px;
  }
}

.bg-video-status- {
  &assigned,
  &recorded,
  &uploaded {
    width: 18px;
    height: 18px;
    background: url('../../assets/video_status_sprite.png') -33px -5px;
  }
}

.bg-video-status-planned {
  width: 18px;
  height: 18px;
  background: url('../../assets/video_status_sprite.png') -61px -5px;
}
