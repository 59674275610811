.squads-view-container {
  & .squads-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  & .calender-table {
    width: 100%;
    border: 0.5px solid rgba($color-gray-80, 1);
    border-radius: 5px;
    // overflow-y: scroll;
    display: flex;
    flex-direction: column;
    & > .table-header {
      display: flex;
      // align-items: center;
      border-bottom: 0.5px solid rgba($color-gray-80, 1);
      .header-dates {
        display: flex;
        width: 100%;
        align-items: flex-start;
        overflow-x: hidden;
        & > .calender-header-cell {
          flex: 1;
          min-width: 100px;
        }
      }
      & .calender-header-cell {
        border-right: 0.5px solid rgba($color-gray-80, 1);
        height: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:last-child {
          width: 90px;
          border-right: unset;
        }
        &:first-of-type {
          min-width: 130px;
          width: 130px;
        }
        &:last-of-type {
          margin-left: auto;
        }
        &.arrow-btn {
          background-color: unset;
          border-left: 0;
          border-top: 0;
          border-bottom: 0;
          cursor: pointer;
        }
        &.bp3-button.bp3-minimal {
          background-color: $color-gray-95;
          text-decoration: unset !important;
          border-radius: unset;
          padding: unset !important;
          margin: unset !important;
          & > .bp3-button-text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;

            & .date-span {
              @include shift-title;
              line-height: 15px;
            }
          }
          &.active {
            background-color: $color-faux-ghost-white;
            border-bottom: 3px solid $color-science-blue;
            & > .bp3-button-text {
              & .date-span {
                @include shift-title;
                line-height: 15px;
                color: $color-science-blue;
              }
            }
          }
        }
      }

      &.button {
        background-color: blue;
      }
    }

    & > .table-body {
      display: flex;
      flex-direction: column;
      // border: 0.5px solid rgba($color-gray-80, 0.5);
      & > div {
        border-bottom: 1px solid $color-quick-silver;
      }
      & > div:last-child {
        border-bottom: unset;
      }
      & .calender-body-shift-type {
        width: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 7px;
        flex: none;
        border-right: 1px solid $color-quick-silver;
        min-height: 110px;
        overflow: hidden;
        &.morning {
          background-color: $color-tequila-orange !important;
        }

        &.morning2 {
          background-color: $color-caramel !important;
        }

        &.night {
          background-color: $color-onahau-blue !important;
        }

        &.night2 {
          background-color: $color-pattens-blue !important;
        }

        &.overnight {
          background-color: $color-alice-blue !important;
        }

        &.overnight2 {
          background-color: $color-malibu-blue !important;
        }

        & > .calender-body-power-content {
          background-color: $color-gray-99;
          border: 1px solid $color-turquoise;
          display: flex;
          border-radius: 16px;
          align-items: center;
          justify-content: center;
          width: 85px;
          height: 24px;
        }
        .eta-text {
          @include shift-title;
          text-align: center;
          font-weight: 400;
        }
      }
      & .shift-row-content {
        max-height: 20vh;
        overflow-x: auto;
        width: 100%;
        min-height: 100px;
        flex-wrap: nowrap;
        @include scrollbar;
      }
    }
  }
}
.match-assignment-filters-container {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: flex-end;
  & .match-assignment-filters {
    width: fit-content !important;
  }
}

.match-row-container {
  border: 0.5px solid $color-gray-80;
  border-radius: 5px;
  min-height: 68px;
  vertical-align: middle;
  padding-top: 10px;

  &.unassigned {
    background-color: $color-misty-rose;
  }

  & > .row {
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-around;
  }
  & > .row .bp3-inline {
    gap: 0px;
    & > span:first-of-type {
      @include body-two;
      color: $color-quick-silver;
    }
  }
  & > .row > div > .coloredLabel {
    height: 37px;
  }
}

.unassign-matches-colored-power {
  color: $color-power;
}

.unassign-matches-pagination-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
