.create-category-title {
  display: flex;
  align-items: center;
  gap: 3px;
  div:first-of-type {
    @include h-2;
  }
}

.add-category-form-container {
  display: flex;
  margin: 21px 35px;
  align-items: baseline;
  gap: 53px;
}
.add-category-form-divider {
  margin: 0px 35px;
}

.add-squad-form-container {
  display: flex;
  flex-direction: column;
  margin: 21px 35px;
  // align-items: baseline;
  gap: 21px;
  .add-squad-form-header {
    @include body-one;
  }
  .add-squads-fab-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
  }
}
.bp3-dialog-footer-actions {
  display: flex;
  margin-right: 25px;
  margin-top: 20px;
  margin-left: 25px;
  align-items: center;
}

.edit-category-name-footer {
  display: flex;
  justify-content: flex-end;
  margin-right: 25px;
  gap: unset;
}

.create-category-isqa-header {
  margin-bottom: 20px;
  @include body-two;
  color: $color-quick-silver;
}

// create squads
.footer-action-btns {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: flex-end;
  margin-right: 40px;
}

.add-members-fab-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}
