label.radio-btn-container {
  & > input {
    -webkit-appearance: auto;
    -webkit-accent-color: $color-science-blue;
    accent-color: $color-science-blue;
    border: 1px solid $color-gray-80;
    &.error {
      border: 1px solid red !important;
      color: unset;
      background-color: unset;
    }
  }
  font-family: 'Source Sans Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: $color-dark-charcoal;
  border: 1px solid $color-gray-80;
  border-radius: 5px;
  min-width: 40%;
  width: fit-content;
  box-sizing: border-box;
  padding: 9px 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  &.selected {
    background-color: $color-faux-ghost-white;
  }
  &.minimal{
    border: 0;
    &.selected{
      background-color: transparent;
    }
  }
}

.radio-group-container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
