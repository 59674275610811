.match-view-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > .matches-filter-btn{
    width: 46px;
    height: 32px;
}
      
}


.search-date-container{
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: baseline;
  // gap: 5px;
 
  & > div {
    margin-left: auto;
    & > .search-input-container {
      width: 120px;
      & > .search-icon{
        transition: unset;
    
        &.focused{
          transform: unset;
        
        }
      }

    }
  }
  & > .error {
    width: 120px;
    & >.search-input-container{
    border-color: $color-alizarin;
    
  }
}

}