// Color Variables

// Text and Icons
$color-dark-charcoal: #333333;
$color-quick-silver: #999999;
$color-black: #000000;

// Surfaces
$color-white: #ffffff; // Background
$color-gray-99: #fcfcfc; // Container
$color-signal-white: #f4f4f4; // Search Bar
$color-gray-95: #f2f2f2; // Main BG
$color-gray-80: #cccccc; // Stroke/Divider
$color-silver: #cacaca; // Didabled Button
$color-faux-ghost-white: #f5f9ff; // Selection Highlight
$color-body-bg: #e5e5e5;
$color-misty-rose: #fee2e3;
$color-lemon-chiffon: #fff8cb;
$color-fire-brick: #841616;
$color-oyster-pink: #eacecf;
$color-newyork-pink: #e1888b;

// Buttons
$color-science-blue: #0044cc; // selection
$color-dusky-blue: #4960ad; // main text
$color-indigo: #040482; // main button
$color-deluge: #7c7ca5; // button hover

// Secondary
$color-alizarin: #dc2228; //error
$color-pigment-green: #1bb55c; //success
$color-bright-sun: #ffe144; //quality Icon
$color-turquoise: #4acbce; //power Icon
$color-pear-green: #e2fee5;
$color-Pigment-Green: #1bb55c;

// Shifts
$color-vivid-orange: #ff9213; // Morining Icon
$color-dodger-blue: #18a0fb; // Night Icon
$color-anakiwa-blue: #9fd9ff; // Overnight Icon
$color-endeavour-blue: #0065aa; // overnight2 Icon
$color-tequila-orange: #ffe8c8; // Morning BG
$color-onahau-blue: #d2edff; // Night BG
$color-alice-blue: #f5fbff; // OverNight BG
$color-malibu-blue: #71c5ff; //overnight2 BG
$color-power: #2bb6b9;
$color-mercury-gray: #e7e7e7;
$color-dark-orange: #ff9400;
$color-caramel: #ffd59c;
$color-navy-blue: #0c80d2;
$color-pattens-blue: #c8dbe8;

//shadow
$shadow-one: 0px 4px 5px rgba(51, 51, 51, 0.14);
$shadow-two: 0px 1px 10px rgba(51, 51, 51, 0.12);
$shadow-three: 0px 2px 4px rgba(51, 51, 51, 0.2);
