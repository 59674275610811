.settings-header {
  margin: 34px 26px 0px 26px;
}

.main-category-section-container {
  border: 0.5px solid rgba($color-gray-80, 0.5);
  border-radius: 5px;
  margin: 20px 26px;
  & .main-category-section-title {
    margin: 24px 40px 0px 40px;
    display: flex;
    align-items: center;
    gap: 30px;
  }

  .settings-card-container {
    @include expandable-card;
    border: 0.5px solid rgba($color-gray-80, 0.5);
    width: 54.5%;
    margin: 15px 40px;
    &:last-of-type {
      margin-bottom: 25px;
    }

    & > .card-more-data {
      & .card-more-data-row {
        display: flex;
        background: white;
        margin-left: 53px;
        width: 75%;
        padding: 18px 40px;
        align-items: center;
        margin-bottom: 15px;
        border: 0.5px solid rgba($color-gray-80, 0.5);
        border-radius: 5px;
        .card-more-data-row-slaOffset {
          display: flex;
          justify-content: center;
          gap: 6px;
          align-content: center;
          align-items: center;
        }

      }
    }
    & > .card-main-data {
      & > .cell {
        width: 200px;
        &:last-child {
          width: 300px;
        }
        & > .dropdown-button-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          background: none;
          border: none;
          border-radius: 50%;
          width: 25px;
          height: 25px;
          &:hover {
            background: $color-gray-80;
            cursor: pointer;
          }
        }
      }
    }
  }
  @include for-size(desktop-down){
    .settings-card-container{
      width: 80%;
    }
  }
    @include for-size(desktop-up){
      .settings-card-container{
        width: 60%;
      }
  }
}

.Add-priorty-category-container {
  display: flex;
  margin: 21px 35px;
  align-items: unset;
  gap: 10px;
  flex-direction: column;
  .Add-priorty-category-error-msg {
    width: 50%;
    height: 35px;
  }
}

.priorities_rank_container {
  margin: 20px 37px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  & > p{
    @include body-one
  }
}
.card-rank-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.list-number{
  @include body-one;
  color: $color-quick-silver;
  width: 24px;
}
.card_rank {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  border: 0.5px solid rgba($color-gray-80, 0.5);
  border-radius: 5px;
  height: 60px;

  & > .cell {
    width: 22%;
    @include body-one;
    display: flex;
    align-items: center;
    justify-content: center;
    &:nth-last-child(-n+2){
      width: 25%;
      color: $color-quick-silver;
    }
  }

  &:hover{
    background-color: $color-faux-ghost-white;
  }
}

.move-handle{
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: move;
  height: 100%;
  margin-left: 20px;
  width: 7.5px;
  background-image: url('../../../assets/customIcons/move.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}