.fab-component {
  background-color: $color-gray-80;
  height: 32px;
  border-radius: 15px;
  padding: 4px 12px;
  display: flex;
  gap: 10px;
  align-items: center;
  width: fit-content;
  .fab-component-text {
    @include btn-text;
  }
  .fab-icon {
    background-color: $color-quick-silver;
    border-radius: 50%;
    color: $color-white;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
