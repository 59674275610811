.shift-attendance-card {
  // width: 114px;
  height: 57px;
  border-radius: 5px;
  padding: 6px 9px;
  position: relative;
  overflow: hidden;
  // min-width: 114px;
  transition: background-color 900ms linear;
  & div:nth-child(2) {
    @include shift-title;
    & > span {
      padding-left: 5px;
    }
    & > object {
      vertical-align: text-top;
    }
  }
  & > .accent {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 5px;
  }
  button {
    & > span {
      display: block;
    }
    all: unset;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    padding-left: 7px;
  }

  &.morning {
    background-color: $color-tequila-orange;
    & > .accent {
      background-color: $color-vivid-orange;
    }
  }

  &.morning2 {
    background-color: $color-caramel;
    & > .accent {
      background-color: $color-dark-orange;
    }
  }

  &.night {
    background-color: $color-onahau-blue;
    & > .accent {
      background-color: $color-dodger-blue;
    }
  }

  &.night2 {
    background-color: $color-pattens-blue;
    & > .accent {
      background-color: $color-navy-blue;

    }
  }

  &.overnight {
    background-color: $color-alice-blue;
    & > .accent {
      background-color: $color-anakiwa-blue;
    }
  }

  &.overnight2 {
    background-color: $color-malibu-blue;
    & > .accent {
      background-color: $color-endeavour-blue;
    }
  }

  &.disabled {
    background-color: $color-gray-95;
    & > .accent {
      background-color: $color-quick-silver;
    }
  }
}
.vacation-types-menu {
  &.bp3-menu {
    height: 280px;
    overflow-y: scroll;
    @include scrollbar;
  }
}
