.reset-container{
    display: flex;
    flex-direction: column;
    margin: 20px;
    gap: 15px;
    margin: 32px 32px 20px 32px;
    
}
 .bp3-dialog-footer {
    & > .bp3-dialog-footer-actions{
        & > .bp3-button{
            min-width: 70px !important;
            text-align: center !important; 
        
    }
    }
}