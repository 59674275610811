
.filters-container {
  position: relative !important;
  right: 95px !important;
  bottom: 3px !important;

&.pc-assignment-filters {
    max-width: 600px;
    gap: 15px;
    .filters-card {
      & > .filters-content {
        button.satellite-filter-dropdown{
          width: 200px;
        }
      }
    }
  }
  .close-filter{
    width: min-content;
    margin-bottom: 5px;
    background-image: unset;
    background-color: unset;
    box-shadow: unset;
    margin-left: auto;
    // padding-top: 0px;
  }


  .filters-card {
    width: 100% !important;
    display: flex !important;
    flex-direction: column;
    margin: 0px;
    padding: 15px;
        margin-top: 15px;

    & > .filters-content {
      display: flex;
      flex-wrap: wrap;
      gap: 9px;
      button {
        width: fit-content;
      }
    }

    & > .filters-buttons {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
      gap: 13px;
    }
  }
}
