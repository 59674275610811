.squads-container {
  padding: 25px 36px;
  .squad-rows {
    display: flex;
    justify-content: flex-start;
    column-gap: 10px;
    // margin-top: 36px;
  }
  .squad-rows {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    .squad-row-container {
    border: 1px solid rgba($color-gray-80,0.5);
      border-radius: 10px;
      padding: 25px 40px;
      margin-bottom: 20px;
      .create-category-conatiner {
        display: flex;
        gap: 10px;
        align-items: baseline;
        svg {
          cursor: pointer;
        }
      }
    }

    .bp3-card{
      padding: 15px 25px 25px 25px;
    }
    .category-title {
      @include h-1;
      text-transform: uppercase;
    }
      .squad-row-buttons {
        display: flex;
        flex-direction: column;
        text-decoration: underline;
        align-items: flex-end;
        margin-left: auto;
        width: 22%;
        align-items: flex-end;
        justify-content: flex-start;
        margin-top: 25px;
      }
      .block-link-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        flex: 1 0 82%;
      }
    }
  }

.squad-card-container{
  @include expandable-card;
  min-width: 650px;
  // width: fit-content;
  &.expanded{
    width: 100%;
    .user-management-card-container{
      & > .expanded-card{
        background-color: $color-white;
      }
    }
    & > .card-main-data {
    border-bottom: 0.5px solid $color-gray-80  !important;
    padding-bottom: 15px;
    }
  }
}




.squads-grid-container{
  border: 0.5px solid $color-gray-80;
  border-radius: 5px;
  background-color: #FFFFFF;
  padding-top: 18px;
  padding-bottom: 18px;
  @include h-2;
  margin-inline: 1px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  margin-bottom: 16px;

  &.expanded{
    background-color: $color-faux-ghost-white;
    margin-bottom: unset;
    width: unset;
  }
   .expand-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    padding-left: 20px;
    &:hover {
      cursor: pointer;
    }
  }
 
}
.squads-expanded-card{
  border: 0.5px solid $color-gray-80;
  border-top: unset;
  padding-top: 18px;
  border-radius:5px;
  margin-inline: 1px;
  padding-bottom: 18px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  background-color: $color-faux-ghost-white;
  margin-bottom: 16px;
  .user-management-card-container .expanded-card {
    background-color: #FFFFFF !important;
  }
}

