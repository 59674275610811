.tab-btn{
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
  padding: 12px 49px 14px 48px;
  font-family: 'Source Sans Pro';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: $color-quick-silver;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  &.small{
    @include body-two;
    padding: 12px 36px 12px 36px;
    &.active{
      font-weight: 700;
    }
  }
  &:hover{
    text-decoration: none;
    color: $color-science-blue;
    background-color: $color-faux-ghost-white;
  }
  &.active{
    color: $color-science-blue;
    background-color: $color-faux-ghost-white;
    &.horizontal:after{
      position: absolute;
      content: "";
      z-index: 1;
      bottom:0px;
      left: 0px;
      height: 3px;
      width: 100%;
      border-bottom: 3px solid $color-science-blue;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;

    }

    &.vertical:after{
      position: absolute;
      content: "";
      z-index: 1;
      right:0px;
      top: 0px;
      width: 3px;
      height: 100%;
      border-right: 3px solid $color-science-blue;
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
    }
  }
  &.bp3-button.bp3-minimal{
    text-decoration: unset !important;
    .bp3-button-text{
      align-items: center;
      display: flex;
    }
    &:hover{
      text-decoration: none;
      color: $color-science-blue;
      background-color: $color-faux-ghost-white !important;
    }
  }
}

.tab-group{
  display: flex;
  overflow: hidden;
  &.vertical{
    flex-direction: column;
  }
  & .tab-button{
    justify-content: center;
    margin-left: auto;
    display: flex;
    margin-right: 81px;
    text-decoration: underline;
  }
}
