.users-container{
  @include for-size(desktop-up){
    .filters-bar-search-box{
      width: 23%;
    }
  }

  @include for-size(desktop-up){
    .filters-bar-search-box{
      width: 23%;
    }
  }
}

.user-list-header{
  @include list-header;
  display: flex;
  overflow: hidden;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: $color-signal-white;
  padding: 9px 0px;
  & > .content{
    width: calc(100% - 100px);
    display: flex;
    align-items: center;
    flex-wrap:wrap;
    & > *{ flex: 1 }
  }
  & > .action{
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.user-management-card-container{
  & > .user-card-header{
    min-height: 60px;
    background-color: $color-white;
    align-items: center;
    border: 0.5px solid $color-gray-80;
    border-radius: 5px;
    display: flex;
    &.borders-alignment{
      border-radius: unset;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    & > .content{
      width: calc(100% - 100px);
      min-height: 60px;
      display: flex;
      flex-wrap:wrap;
      align-items: center;
      overflow: hidden;
      & > *{
        flex: 1;
        word-break:normal;
      }
    }
    & > .action{
      width: 50px;
      min-height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .deactivated{
    background-color: $color-gray-80 !important;
  }
  .expanded{
    background-color: $color-faux-ghost-white;
  }
  .expanded-card{
    min-height: 300px;
    border: 0.5px solid $color-gray-80;
    border-top: unset;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    .deactivated{
      background-color: $color-gray-80 !important;
    }
  }
}

  @media screen and (max-width: 768px) {
    .user-management-card-container{
      & .user-card-header{
        & > .content{flex-direction: column}
      }
    }
    .user-list-header{
      & > .action{
        width: 0px;
      }
      & > .content{
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
    .filters-bar-search-box{
      flex-wrap: wrap;
    & > :last-child{
      margin-left: unset;
    }
  }

  }
