.button-link{
    color: $color-quick-silver;
    padding: 6px 14px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    border: 0.5px solid rgba($color-gray-80,0.5);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 150px;
    &.active{
      color: $color-science-blue;
      border: 0.5px solid rgba($color-science-blue,0.5);
    }
  }