.bread-crumb-container {
  display: flex;
  align-items: center;
  margin: 16px 12px;
  gap: 5;
  & div {
    @include label-text;
    & span:last-of-type {
      color: $color-science-blue;
    }
  }
}
