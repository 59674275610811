.bp3-button{
  font-family: 'Source Sans Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.1px;
  text-align: left;
  box-shadow: none;
  background-image: none;
  border-radius: 5px;
  padding: 9px 15px;

  &.bp3-intent-primary{
    // background-color: $color-indigo;
    // border-color: $color-indigo;
    color: $color-white;
    &:hover{
      background-color: #7C7CA5 !important;
      border-color: #7C7CA5;
    }
    &.bp3-disabled{
      color: $color-dark-charcoal;
      background-color: #CACACA;
      &:hover{
        color: $color-dark-charcoal;
        background-color: #CACACA !important;
      }
    }

    &.bp3-outlined{
      background-color: $color-white;
      color: $color-indigo;
      border-color: $color-indigo;
      &:hover{
        background: $color-white !important;
        border-color: #7C7CA5;
        color: #7C7CA5;
      }
      &.bp3-disabled{
        color: $color-quick-silver;
        background-color: $color-white ;
        &:hover{
          color: $color-quick-silver;
          background-color: $color-white  !important;
        }
      }
    }

    &.bp3-minimal{
      border: 0;
      background-color: transparent;
      text-decoration: underline !important;
      color: $color-indigo;
      &:hover, &:active{
        background-color: transparent !important;
        color: $color-indigo;
      }
      &.bp3-disabled{
        color: $color-dark-charcoal;
        &:hover{
          color: $color-dark-charcoal;
        }
      }
    }
  }
  &.bp3-minimal{
    text-decoration: underline !important;
    &:hover, &:active{
      background: transparent !important;
    }
  }
  &.bp3-outlined{
    &:active, &.bp3-active{
      color:$color-science-blue !important;
      background-color: white;
      border-color: $color-science-blue;
      .bp3-icon svg{
        color: $color-science-blue !important;
      }
    }
    @include for-size(tablet-portrait-up) {
      &.bp3-button{
        min-width: unset !important;

      }
      
    }
    @include for-size(phone-only)  {
      &.bp3-button{
        min-width: unset !important;

      }
    }
  }
  &.light{
    @include light-btn-text
  }
}

button:disabled, button[disabled]{
  cursor: not-allowed !important;
}

.btn-tertiary{
  font-family:  'Source Sans Pro';
  font-Weight: 600;
  font-Size: 14px;
  line-height: 17.6px;
  letter-spacing: 0.25px;
  color: $color-quick-silver !important;
  text-decoration-line: underline;
}

