.bp3-dialog {
  width: 541px;
  background: $color-white;
}
.create-edit-form-container {
  .create-edit-section-title {
    @include body-one;
    margin-bottom: 12px;
  }
  .create-edit-contact-title {
    @include body-one;
    margin-bottom: 12px;
    margin-top: 15px;
  }
  .create-edit-sec-row {
    display: flex;
    gap: 30px;
    margin: 20px 0px;
    flex-wrap: wrap;
  }
}
.create-edit-header {
  display: flex;
  align-items: baseline;
  margin-top: 15px;
  & > p {
    @include h-2;
  }
}
