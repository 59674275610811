.bg-overnight2 {
    display: inline-block;
    width: 18px; height: 18px;
    background: url('../../assets/shifts_sprite.png') -18px -0;
}

.bg-overnight2-disabled {
    display: inline-block;
    width: 18px; height: 18px;
    background: url('../../assets/shifts_sprite.png') -0px -0;
}

.bg-overnight {
    display: inline-block;
    width: 18px; height: 18px;
    background: url('../../assets/shifts_sprite.png') -54px -0;
}

.bg-overnight-disabled {
    display: inline-block;
    width: 18px; height: 18px;
    background: url('../../assets/shifts_sprite.png') -36px -0;
}

.bg-night {
    display: inline-block;
    width: 18px; height: 18px;
    background: url('../../assets/shifts_sprite.png') -126px -0;
}

.bg-night-disabled {
    display: inline-block;
    width: 18px; height: 18px;
    background: url('../../assets/shifts_sprite.png') -108px -0;
}


.bg-night2 {
  display: inline-block;
  width: 18px; height: 18px;
  background: url('../../assets/shifts_sprite.png') -90px -0;
}

.bg-night2-disabled {
  display: inline-block;
  width: 18px; height: 18px;
  background: url('../../assets/shifts_sprite.png') -72px -0;
}

.bg-morning {
    display: inline-block;
    width: 18px; height: 18px;
    background: url('../../assets/shifts_sprite.png') -198px -0;
}

.bg-morning-disabled {
    display: inline-block;
    width: 18px; height: 18px;
    background: url('../../assets/shifts_sprite.png') -180px -0;
}

.bg-morning2 {
  display: inline-block;
  width: 18px; height: 18px;
  background: url('../../assets/shifts_sprite.png') -162px -0;
}

.bg-morning2-disabled {
  display: inline-block;
  width: 18px; height: 18px;
  background: url('../../assets/shifts_sprite.png') -144px -0;
}
