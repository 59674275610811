// Reassign
.squad-power-and-capacity-info-card {
  display: flex;
  width: 256px;
  height: 66px;
  border: 0.5px solid rgba($color-gray-80, 0.5);
  background-color: $color-gray-99;
  border-radius: 5px;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;

  &.active-card {
    border-color: $color-science-blue;
  }
  & > .calender-colored-power-incomplete {
    color: $color-alizarin;
  }

  & > .calender-colored-power-complete {
    color: $color-power;
  }
}

.replace-pagination {
  & > .pagination-container {
    margin: unset;
    padding: unset;
    & > .pages {
      margin-right: 25px;
    }
  }
  .active-shift-match {
    border-color: $color-science-blue !important;
    border-width: 1px;
  }
}

.pagination-limitation-container {
  display: flex;
  align-items: baseline;
  justify-content: center;
  & > .bp3-button.bp3-intent-primary.bp3-minimal {
    text-decoration: none !important;
    padding: unset !important;
  }
}

.assign-tasks-container {
  padding: 14px 32px 0px 32px;
  & > .dropdowns-sections {
    padding-block: 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}

.team-side-title {
  @include body-one;
  width: 250px;
}
.assigned-task-sec-headers {
  @include body-four;
  width: 100px;
}
.assigned-task-sec-body {
  @include body-four;
  color: $color-quick-silver;
  & > span:first-child {
    width: 250px;
  }
  & > span:not(:first-of-type):not(:last-of-type):nth-last-of-type(2) {
    width: 100px;
  }

  & > .bp3-popover-wrapper {
    button {
      width: 130px;
      border: unset;
      padding: unset;

      .bp3-icon svg {
        color: $color-science-blue;
      }
    }
  }
}

.match-task-content {
  display: flex;
  gap: 80px;
  background-color: $color-gray-99;
  border-radius: 5px;
  border: 0.5px solid rgba($color-gray-80, 0.5);
  margin-block: 10px;
  margin-right: 15px;
  align-items: center;
  height: 60px;
  // justify-content: center;
  // width: 75%;
  & > div span span div .bp3-button {
    width: unset;
    padding: unset;
    border: unset;
    padding-left: 5px;
  }
}

.view-details-modal {
  .issues-table-title-row {
    padding-top: 21px;
    & > .issues-table-title {
      padding-bottom: 6px;
      @include body-three;
      color: $color-quick-silver;
    }
    & > .issues-table-title-resolved {
      padding-bottom: 6px;
      @include body-three;
      color: $color-pigment-green;
      font-style: italic;
    }
    & > .issues-table-body {
      padding-bottom: 6px;
      @include body-two;
    }
  }
  .details-text {
    padding-block: 5px;
    font-style: italic;
  }
  .edited-text {
    @include body-three;
  }
  & > .manage-matches-button {
    margin-left: 15px;
    padding-block: 15px;
  }
  .divider:last-child {
    display: none;
  }
  & > .issues-body-container {
    overflow-y: scroll;
    @include scrollbar;
    height: 500px;
  }
  & > .empty-issues-body-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
  }
}

.report-issue,
.block-issue {
  .radio-group-container {
    &.missing-player-issue-group {
      & .radio-btn-container {
        border: none;
        padding-left: 0;
        &.selected {
          background-color: transparent !important;
        }
      }
    }
  }

  & > .report.bp3-dialog-footer-actions {
    justify-content: space-between;
  }
  .quality-issue-container {
    & > .radio-group-container {
      &.quality-issue-group {
        flex-direction: column;
        gap: 13px;
        & > .radio-btn-container {
          border: none;
        }
      }
    }

    & > .bad-shirt-container {
      margin-block: 20px;
      & > .radio-group-container {
        padding-top: 20px;
        padding-bottom: 16px;
        &.quality-issue-group {
          flex-direction: column;
          gap: 13px;
          & > .radio-btn-container {
            border: none;
          }
        }
      }
    }
  }

  .missing-player-container {
    & > .radio-group-container {
      &.missing-player-issue-group {
        flex-direction: column;
        & .radio-btn-container {
          border: none;
          &.selected {
            background-color: none !important;
          }
        }
      }
    }
  }
  .missing-player-container,
  .time-stamp-container {
    .timestamp-info-container {
      padding-top: 15px;
      @include list-header;
      font-style: italic;
    }
  }
  .time-stamp-container {
    display: flex;
    flex-direction: column;
    padding-left: 13px;
    & > .horizontal-label.timeStamp span {
      font-style: italic;
      font-weight: 400;
      font-size: 0.875rem;
      color: $color-quick-silver;
    }

    & > div .time-picker-container .rmdp-container > .rmdp-input {
      width: 74px;
      height: 37px;
      padding: 10px;
    }
  }
}

.resolve-issue-container {
  .divider:last-child {
    display: none;
  }

  & .resolve-all-checkbox {
    & > .resolve-all-label-text {
      @include body-one;
      color: $color-endeavour-blue;
    }
  }

  & .header-text {
    @include body-three;
    color: $color-quick-silver;
    padding-bottom: 6px;
  }
  & .content-text {
    @include body-two;
    & > .details-text {
      padding-block: 5px;
      font-style: italic;
    }
  }
  input[type='time']::-webkit-calendar-picker-indicator {
    background: none;
    display: none;
  }
}

.view-tasks-container {
  & > .titles-container {
    &.row {
      @include label-header;
    }
  }
  & > .details-body {
    &.row {
      @include body-three;
      &.bold-titles {
        font-weight: 600;
        & > .font-style-match-half {
          font-style: italic;
        }
      }
    }
  }

  .tasks-dashes {
    color: $color-quick-silver;
  }
}
