.pagination-container{
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap;
  color: $color-quick-silver;
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  & span{
    font-family: "Roboto";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.10000000149011612px;
    text-align: left;
  }

  & > .per-page{
    margin-left: 80px;
  }
  & > .pages{
    margin-left: auto;
    & > button {
      background: none;
      border: none;
      padding: 7px;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      color: $color-quick-silver;
      &.selected{
        color: $color-science-blue;
      }
    }
    &.pagination-item.dots{
      // padding: 7px;
    }
  }
}


@media only screen and (max-width: 767px) {
  .pagination-container{
    flex-direction: column;
    align-items: flex-start;
     .pages{
      margin-left: 0;
      & > button{
        padding: 7px 5px;
      }
    }
    .per-page{
      margin-left: 0;
    }
  }
}
