.side-list {
  color: $color-quick-silver;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 24px;
  letter-spacing: 0.1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 150px;
  // border-radius: 5px;
  // border: 0.1px solid rgba($color-quick-silver, 0.1);
  // border-top-left-radius: 5px;
  // border-bottom-left-radius: 5px;

  .side-list-item {
    color: $color-quick-silver;
    padding: 6px 14px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    border: 0.1px solid rgba($color-quick-silver, 0.2);
    border-radius: unset;
    // border-bottom-left-radius: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-width: 150px;
    text-decoration: unset !important;
    &:first-of-type {
      border-top-left-radius: 5px;
    }
    &:last-of-type {
      border-bottom-left-radius: 5px;
    }
    &.active {
      color: $color-science-blue;
      border: 0.1px solid rgba($color-science-blue, 0.4);
      background-color: $color-faux-ghost-white;
    }
  }
}
