.main-layout{
  background-color: $color-body-bg;
  min-height: calc(100vh - 64.8px);
  position: relative;
}
.main-content{
  background: $color-white;
  border-radius: 5px;
  margin: 32px;
  width: calc(100vw - 154px);
  &.side-expanded{
    max-width: calc(100vw - 364px);
  }
}
@include for-size(phone-only){
  .main-content{
    margin: 0px;
    width: 100%;
    &.side-expanded{
      max-width: 100%;
    }
  }
}
