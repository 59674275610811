.dashboard-container {
  flex-direction: column;
  padding-bottom: 35px;
  padding-left: 25px;
  padding-right: 25px;
  .header-date {
    text-align: right;
  }
  .dashboard-header {
    display: flex;
    align-items: flex-start;
    overflow: visible;
    gap: 15px;
    padding-top: 20px;
    padding-bottom: 25px;
    flex-wrap: wrap;
  }
  .collapse-match-row {
    display: flex;
    margin-top: 20px;
    padding: 12px 18px;
    border-radius: 5px;
    border: 0.5px solid rgba($color-gray-80, 0.5);
    align-items: center;
    overflow-x: hidden;
    &.expanded {
      border-bottom-left-radius: unset;
      border-bottom-right-radius: unset;
      // border-bottom:unset;
    }
    & > .action {
      min-width: 16px;
      min-height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:last-of-type {
        margin-left: auto;
      }
    }
    & > .content {
      width: calc(100% - 104px);
      display: grid;
      grid: auto / 8% 10% 6% 22% 15% 18% 18% 3%;
      gap: 10px;
      & > div {
        display: flex;
        align-items: center;
      }
      & > .country-row {
        align-items: center;
        justify-content: center;
      }
      @include for-size(desktop-up) {
        &.video {
          grid: auto / 8% 10% 6% 17% 16% 18% 18% 3%;
        }
        & > .country-row {
          display: flex;
        }
      }

      & .match-card-label {
        min-width: 55px !important;
      }

      @include for-size(desktop-down) {
        grid-template-columns: 1fr;
        grid-auto-flow: row dense;
        & > div {
          justify-content: center;
        }
      }
    }
    & > .bp3-inline,
    & > div .bp3-inline {
      gap: 5px;
      & > span:first-of-type {
        @include body-two;
        color: $color-quick-silver;
      }
    }

    .vs-styled {
      color: $color-deluge;
    }
    .country-row {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      gap: 5px;
    }
  }
  .collapse-more-data {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow-x: hidden;
    &.expanded {
      border-bottom: 0.5px solid rgba($color-gray-80, 0.5);
      border-left: 0.5px solid rgba($color-gray-80, 0.5);
      border-right: 0.5px solid rgba($color-gray-80, 0.5);
      padding: 0px 20px;
    }
    .sections-wrapper {
      display: flex;
      & > div {
        border-right: 0.5px solid $color-gray-80;
        padding: 0.5rem;
        padding-right: 5rem;
        flex: 1 0 300px;
        &:last-of-type {
          border: none;
          padding-right: 0;
        }
        &:first-of-type {
          padding-left: 0;
        }
      }
    }
    @include for-size(tablet-landscape-down) {
      .sections-wrapper {
        display: grid;
        grid: repeat(2, auto) / auto-flow 48%;
        & > div {
          border: none;
          padding-right: 0;
          padding-left: 0;
        }
      }
    }
    .tasks-section-manage-match-container {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      gap: 0.5rem;
      padding-right: 0.5rem !important;
    }
    @include for-size(big-desktop-up) {
      .tasks-section-manage-match-container {
        width: 28rem;
      }
    }
    .dashboard-collapse-section {
      display: flex;
      flex-direction: column;
      align-items: baseline;
    }

    .match-part-container {
      display: flex;
      align-items: center;
      & > span {
        @include body-two;
        color: $color-quick-silver;
      }
    }

    .view-details-button-container {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
    }
    @include for-size(big-desktop-up) {
      .view-details-button-container {
        width: 28rem;
      }
    }

    .view-details-status {
      display: flex;
      align-items: baseline;
      gap: 10px;
      padding-right: 0.5rem;
    }
  }
  .expanded {
    background-color: $color-faux-ghost-white;
  }
  @include for-size(phone-only) {
    .header-date {
      text-align: left;
    }
    .country-row {
      display: flex;
      align-items: center;
    }
  }
}
.date-with-filter-Bar {
  display: flex;
  align-items: center;
  gap: 10px;
  & > .bp3-popover-wrapper {
    button {
      width: 155px;
    }
  }
}
