.expandable-list-item{
  display: flex;
  flex-direction: column;
  border: 1px solid $color-gray-80;
  border-radius: 5px;
  padding: 12px 24px;
  &:not(:last-child){
    margin-bottom: 12px;
  }
  .head{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 32px;
    line-height: 28px;

    &>.user-avatar{
      display: flex;
      align-items: center;
      img{
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-right: 5px;
      }
    }

    &>.actions-icon{
      border-radius: 50%;
      width: 24px;
      height: 24px;
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 150ms background-color ease-in-out;
      cursor: pointer;

      &:hover{
        background-color: $color-signal-white;
      }
    }

    &>img{
      width: 16px;
      height: 16px;

      &:hover{
        background-color: $color-signal-white;
      }
      &.collapsed{
        transform: rotate(-90deg);
      }
    }
  }

}
