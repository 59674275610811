.search-input-container{
  border: 1px solid $color-signal-white;
  border-radius: 5px;
  background-color: $color-signal-white;
  padding-left: 15px;
  display: flex;
  align-items: center;
  & > input{
    border-radius: 5px;
    width: 100%;
    height: 100%;
    color: $color-quick-silver;
    background-color: $color-signal-white;
    text-decoration: none;
    padding: 9px 15px 9px 0px;
    margin-left: 4px;
    font-family: 'Source Sans Pro';
    font-size: 0.875em;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125em;
    letter-spacing: 0;
    outline: none;
    border: none;
    &::placeholder{
      color: $color-quick-silver;
    }
    &:focus{
      background-color: $color-faux-ghost-white;
      border-color:$color-faux-ghost-white ;

    }
  }
  & > .search-icon{
    transition: all 1s ease-in-out;
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    -ms-transition: all 1s ease-in-out;
  }

  & > .cross-button{
    margin-left: auto;
    padding-right: 15px;

  }
  &:focus-within{
    background-color: $color-faux-ghost-white;
    border-color:$color-faux-ghost-white ;
  }
}

input.search-box{
  border: 0;
  border-radius: 5px;
  padding: 12px 10px 12px 21px;
  margin-bottom: 10px;
  background-color: $color-signal-white;
}
