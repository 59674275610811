@mixin card-border {
  border: 0.5px solid $color-gray-80;
  border-radius: 5px;
}
.card-border {
  @include card-border;
}
.card {
  @include card-border;
  display: flex;
  padding: 10px;
  flex-direction: column;
  width: 333px;
  min-height: 200px;
  box-shadow: $shadow-two;
  .bp3-inline {
    gap: 5px;
    & > span:first-of-type {
      @include body-two;
      color: $color-quick-silver;
    }
  }
  &-header {
    display: flex;
    height: 50px;
    justify-content: space-between;
    align-items: center;
    & .comp-name {
      max-width: 140px;
    }
    & .country-name {
      max-width: 100px;
    }
  }
  &-body {
    height: 100px;
    display: flex;
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: center;
    &.pc-card-body,
    &.league-card-body {
      justify-content: space-between;
      .satellites-container {
        display: flex;
        gap: 10px;
        align-items: center;
        .satellites-content {
          display: flex;
          gap: 10px;
          flex-wrap: wrap;
          max-width: 200px;
          justify-content: flex-end;
          flex-direction: column;
          align-items: flex-end;
        }
      }
    }
    &.provider-card-body {
      gap: 5px;
    }
  }

  &-footer {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.team-competition-content {
  .styled-team-name {
    @include body-one;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    width: auto;
    max-width: calc(50% - 10px);
    display: inline-block;
    vertical-align: middle;
    justify-content: start;
  }

  .vs-styled {
    color: $color-deluge;
  }
}

.card-match-pc {
  border: 0.5px solid $color-gray-80;
  border-radius: 5px;
  padding: 0;
  margin: 0;
  background-color: $color-misty-rose;
  &:not(.isActive):not(.isAssigned):not(.isHistory) {
    &:hover {
      background-color: $color-oyster-pink;
      cursor: pointer;
    }
  }
  & > .row {
    align-items: center;
  }
  &.isActive:not(.isAssigned):not(.isHistory) {
    border: 2px solid $color-newyork-pink;
  }
  &.isAssigned:not(.isHistory) {
    background-color: $color-white;
    &.isActive {
      border: 2px solid $color-science-blue;
    }
  }
  &.isHistory {
    filter: grayscale(1);
    background-color: $color-gray-99;
  }
}

.pc-name-card {
  background-color: $color-fire-brick;
  color: $color-white;
  border-radius: 5px;
  padding: 16px;
  text-align: center;
}
