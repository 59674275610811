.sign-out-container{
margin: 30px;
    display: flex;
    gap: 10px;
    flex-direction: column;
    & >  .sign-out-confirmation-message{
        @include body-one;
        line-height: 1.5rem;
    
    }
}