.video-acq-Container {
  padding-left: 0;
  padding-right: 0;
  .video-acq-header {
    display: flex;
    align-items: flex-start;
    overflow: visible;
    gap: 15px;
    padding-top: 20px;
    flex-wrap: wrap;
  }
  .filters-bar-children {
    min-height: 38px;  
    &:is(.assigned) > .dropdown-select {
      button {
        width: auto;
      }
    }
  } 
}
