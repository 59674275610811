.dialog-title {
  display: flex;
  align-items: center;
  gap: 5px;
  div:first-of-type {
    @include h-2;
  }
}
.delete-body-container {
  display: flex;
  flex-direction: column;
}
