@import '~@blueprintjs/core/lib/scss/variables';

$pt-font-family: 'Source Sans Pro';
$pt-font-family-monospace: monospace;

$pt-grid-size: 16px;
$pt-text-color: $color-dark-charcoal;

$pt-intent-primary: $color-indigo;
$pt-intent-success: $color-pigment-green;
$pt-intent-warning: $color-alizarin;
$pt-intent-danger: $color-bright-sun;
$menu-min-width: none;

//Blueprint overwrites
.bp3-dialog {
  background: $color-white;
  max-width: 541px;
}
.bp3-toast {
  align-items: center !important;
  display: flex;
  // gap: 10px;
  padding-left: 15px;
  height: 60px;
  min-width: 250px !important;
  padding-right: 15px;
  .bp3-button-group.bp3-minimal .bp3-button:hover {
    background: none;
  }
}

.bp3-button .bp3-icon:first-child:last-child,
.bp3-button .bp3-spinner + .bp3-icon:last-child {
  margin: 0 0 !important;
}
// ul.bp3-menu {
// padding-left: 13px !important;
// padding-right: 13px !important
// }

.bp3-popover-content {
  box-shadow: $shadow-one;
  border-radius: 5px;
  overflow-y: auto;
  max-height: 400px;
  .bp3-input-group {
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
.bp3-progress-bar {
  height: 5px !important;
}

.bp3-progress-bar.bp3-intent-primary .bp3-progress-meter {
  background-color: $color-science-blue !important;
}
.bp3-tooltip-indicator {
  border-bottom: unset !important;
}

.bp3-control {
  display: inline-flex !important;
  align-items: center !important;
  margin: unset;
}
.bp3-tag {
  &.pc-number-tag {
    width: fit-content;
    background-color: $color-misty-rose;
  }
}
.with-check-mark-checkbox {
  margin: 0 !important;
  input:checked ~ .bp3-control-indicator:before {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E");
  }
}

.filters-content {
  & > .pc-assignment-checkbox {
    &.bp3-control {
      margin-bottom: unset;
    }

    &.bp3-checkbox.bp3-control {
      & > .bp3-control-indicator {
        margin-top: unset;
        margin-right: 7px;
      }
    }

    &.bp3-checkbox.bp3-inline {
      gap: 5px;
    }
    &.bp3-control.bp3-inline {
      margin-right: 10px;
    }
  }
}

.bp3-overlay-content {
  margin: 3px 0px;
}

.bp3-tooltip2 > .bp3-popover2-content {
  padding: 6px;
}

.bp3-popover2-content {
  background-color: white;
  width: 100%;
  box-shadow: $shadow-one;
  border-radius: 5px;
}
