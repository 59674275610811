@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,200,900italic,900,700italic,700,600italic,600,400italic,300italic,300,200italic);

@mixin h-1 {
  font-family: 'Source Sans Pro';
  font-size: 1.125em;
  font-style: normal;
  font-weight: 700;
  line-height: 1.4375em;
  letter-spacing: 0.015625em;
  // text-align: left;
  color: $color-dark-charcoal;
}

@mixin h-2 {
  font-family: 'Source Sans Pro';
  font-size: 1.125em;
  font-style: normal;
  font-weight: 600;
  line-height: 1.4375em;
  letter-spacing: 0.015625em;
  // text-align: left;
  color: $color-dark-charcoal;
}
@mixin h-3 {
  font-family: 'Source Sans Pro';
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
  letter-spacing: 0.015625em;
  color: $color-dark-charcoal;
}

@mixin body-one {
  font-family: 'Source Sans Pro';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.125em;
  letter-spacing: 0.015625em;
  text-align: left;
  color: $color-dark-charcoal;
}

@mixin btn-text {
  font-family: 'Source Sans Pro';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.125em;
  letter-spacing: 0.015625em;
  // text-align: left;
  color: $color-dark-charcoal;
}
@mixin light-btn-text {
  font-family: 'Source Sans Pro';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125em;
  letter-spacing: 0.015625em;
  text-align: left;
  color: $color-dark-charcoal;
}
@mixin body-two {
  font-family: 'Source Sans Pro';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125em;
  letter-spacing: 0.015625em;
  // text-align: left;
  color: $color-dark-charcoal;
}

@mixin body-three {
  font-family: 'Source Sans Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.6px;
  letter-spacing: 0.25px;
  // text-align: left;
  color: $color-dark-charcoal;
}

@mixin body-four {
  font-family: 'Source Sans Pro';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.9375em;
  letter-spacing: 0.015625em;
  // text-align: left;
  color: $color-dark-charcoal;
}

@mixin txt-other {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: $color-dark-charcoal;
  // text-align: left;
}

@mixin list-header {
  font-family: 'Source Sans Pro';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.1rem;
  letter-spacing: 0.006rem;
  text-align: left;
  color: $color-quick-silver;
}

@mixin label-header {
  font-family: 'Source Sans Pro';
  font-size: 0.75rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.125em;
  letter-spacing: 0.006em;
  text-align: left;
  color: $color-quick-silver;
}
@mixin label-text {
  font-family: 'Source Sans Pro';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.125em;
  letter-spacing: 0.006em;
  text-align: left;
  color: $color-dark-charcoal;
}

@mixin shift-title {
  font-family: 'Source Sans Pro';
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0.006em;
  text-align: left;
  color: $color-dark-charcoal;
}
