.expandable-card-container {
  width: 100%;

  & .expandable-card-header {
    display: flex;
    align-items: center;
    margin-bottom: 17px;

    .expand-header-style {
      @include body-one;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .video-expandable-matches-count {
    align-self: flex-end;
    @include label-header;
    font-style: italic;
  }
}
