.txt-h-1{
  @include h-1;
}

.txt-h-2{
  @include h-2;
}

.txt-h-3{
  @include h-3;
}

.txt-body-1{
  @include body-one;
}

.txt-body-2{
  @include body-two;
}

.txt-body-3{
  @include body-three;
}

.txt-body-4{
  @include body-four;
}

.txt-other{
  @include txt-other;
}
