.horizontal-label {
  display: flex;
  flex-direction: column;
  gap: 5px;
  & > span:first-child {
    @include label-header;
  }
  & > span:nth-child(2) {
    @include label-text;
  }
}

.bordered-label {
  display: flex;
  gap: 25px;
  border: 0.5px solid rgba($color-gray-80, 0.5);
  border-radius: 5px;
  padding: 6px;
  min-height: 36px;
  align-items: center;
  & > span {
    @include btn-text;
  }
}

.avatar-label {
  display: flex;
  gap: 9px;
  border: 0.5px solid $color-gray-80;
  border-radius: 5px;
  padding: 6px 10px;
  min-height: 36px;
  align-items: center;
  min-width: 112px;
  width: max-content;
  & > span {
    @include btn-text;
  }
}
.colored-label-container {
  border-radius: 5px;
  height: 32px;
  justify-content: center !important;
  margin: 0px 15px;
  padding: 5px;
  padding: 5px;
  min-width: 38px;
  min-height: 25px;
  display: flex;
  align-items: center;
  & > p {
    @include body-one;
    color: $color-white;
    display: contents;
  }
}
.bp3-inline {
  display: flex;
  align-items: center;
  gap: 11px;
  & > span:first-child {
    @include body-one;
  }
}
.simple-label {
  span {
    @include h-2;
  }
  &.simple-label-with-background > span {
    @include body-one;
    background-color: $color-mercury-gray;
    max-width: 184px;
    height: 42px;
    padding: 15px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 112px;
  }
}
.shift-type-label {
  display: flex;
  align-items: center;
  width: 112px;
  height: 36px;
  justify-content: center;
  gap: 5px;
  border-radius: 5px;
}
.shift-label-height {
  height: 42px;
}

.match-status-label {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  min-height: 42px;
  justify-content: center;
  gap: 5px;
  border-radius: 5px;
  min-width: 115px;
  padding: 10px;
  max-width: 130px;
  &.blocked {
    background-color: $color-misty-rose;
    border: 1px solid $color-alizarin;
  }

  &.reported {
    background-color: $color-lemon-chiffon;
  }

  &.completed {
    background-color: $color-pear-green;
  }

  &.notStarted {
    background-color: $color-mercury-gray;
  }

  &.inProgress {
    background-color: $color-lemon-chiffon;
  }
}
.copy-btn {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-decoration: underline;
}
.copied-pop {
  background-color: $color-dark-charcoal;
  color: $color-white;
  padding: 4px;
  border-radius: 5px;
  .bp3-popover2-content {
    background-color: $color-dark-charcoal;
    padding: 0;
  }
}

.flex-1-0-auto {
  flex: 1 0 auto;
  min-width: fit-content;
  width: 50%;
}
