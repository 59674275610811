.edit-squad-power-container {
  margin: 15px 32px;
}

.edit-squad-power-table {

  & > .edit-squad-power-table-header {
     background-color: $color-signal-white;
     height: 36px;
     border: none;
     border-radius: 5px;
     align-items: center;
     margin-left: 24px;
     margin-right: 10px;
     color: $color-quick-silver ;
     .txt-body-2{
      color: $color-quick-silver ;

     }
   }
   .edit-squad-power-table-body{
    margin-left: 24px;
    margin-right: 10px;
    height: 36px;
    align-items: center;
     border: 0.5px solid rgba($color-gray-80, 0.5);
    margin-top: 10px;
    border-radius: 5px;
  }
  }
