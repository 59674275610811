.side-nav-container {
  position: relative;
  background-color: $color-white;
  // width: 260px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  &.expanded{
    min-width: 300px;
  }
  .shrink{
    position: absolute;
    right: 0;
  }
}

.side-nav-sections {
  margin: 0;
  padding: 0;
  & > li {
    display:block;
    margin: 19px 0;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      width: 80%;
      border: 0.5px solid $color-gray-80;
      left: 10%;
    }

    & > .nav-header{
      padding-left: 30px;
      font-family: 'Source Sans Pro';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
    }
  }

  & > :nth-last-child(2),
  & > :last-child {
    &::after {
      border: 0;
    }
  }
}

@include for-size(phone-only){
  .side-nav-container{
    display: none;
    &.expanded{
      display: block;
      position: absolute;
      top:0;
      right:0;
      width: 100%;
      z-index: 4;
    }
    .expand-side{display: none}
    .shrink{display: none}
  }
}
