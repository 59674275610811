.match-card-container {
  display: flex;
  flex-direction: column;
  border: 0.5px solid $color-gray-80;
  border-radius: 5px;
  width: 100%;

  & .bp3-inline{
    gap: unset;
    & > span:first-of-type{
      @include list-header
    }

    & > span:nth-of-type(2){
      @include body-two;
    }
  }

  &.unassigned {
    background-color: $color-misty-rose;
  }
   .match-card-ops-info {

    padding: 11px 9px 11px 9px ;
   & > div > .customize_colored_label {
      height: 30px !important;
      padding: 0px 5px;
      width: 48px;
      margin: unset;
    }
    & > div > .unassigned-label {
       color: $color-alizarin;
       display: flex;
      justify-content: flex-end;

    }

    & > div > .squad-custom-label-text{
      & .bp3-inline{
        & > span:nth-of-type(2){
          @include body-three;
        }
      }
    }
  }

  .match-card-match-info {
    .sla-ko-block{
      display: flex;
      justify-content: flex-end;

    }
    padding: 11px 9px 11px 9px ;
    .styled-team-name{
      display: inline-block;
    }
    .vs-styled{
      color: $color-deluge;
      display: inline-block;
    }
    .eta-label-text{
       color: $color-pigment-green;
     &.eta-passed{
        color: $color-alizarin;
      }
     }
    }
  &.unassigned:hover {
    transform: rotate3d(1, 1, 1, -2deg);
    cursor: move;

  }

  &.disabled-match{
    background-color: $color-gray-80;
    transform:unset !important;
    cursor: not-allowed !important;
    opacity: 0.5 !important;


    pointer-events: all !important;

   .divider{
      border-color: $color-gray-95;
    }
    }
    &.no-access{
      transform:unset !important;
      cursor: not-allowed !important;
      pointer-events: all !important;


      }
}

