.text-input-container{
  border-radius: 5px;
  width: 184px;
  box-sizing: border-box;
  text-align: left;
  position: relative;
  height: fit-content;
  & > label {
    position: absolute;
    top: -6px;
    left: 10px;
    z-index: 1;
    color: $color-quick-silver;
    background-color: $color-white;
    padding: 0px 5px;
    font-family: 'Source Sans Pro';
    font-size: 0.75em;
    font-style: normal;
    font-weight: 400;
    line-height: 0.9375em;
    letter-spacing: 0px;
    text-align: left;
    transition: visibility 0.1s ease-in-out;
    visibility: hidden;
    text-transform: capitalize;
  }
  & > .show-btn{
    position: absolute;
    top: 9px;
    right: 10px;
    z-index: 1;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
	  outline: inherit;
  }
  & > input{
    width: 100%;
    height: 100%;
    color: $color-quick-silver;
    border: 1px solid $color-gray-80;
    text-decoration: none;
    padding: 9px 15px 9px 15px;
    border-radius: 5px;
    font-family: 'Source Sans Pro';
    font-size: 0.875em;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125em;
    letter-spacing: 0;
    &::placeholder{
      text-transform: capitalize;
    }
    &:focus{
      outline: none;
      border: 1px solid $color-science-blue;
      color: $color-dark-charcoal;
      caret-color: $color-science-blue;
      &::placeholder{
        visibility: hidden;
      }
    }
  }
  &:focus-within{
    & > label{
      visibility: visible;
      color: $color-science-blue;
    }
  }
  &.not-empty{
    & > label{
      visibility: visible;
    }
  }
  &.error{
    & > input{
      border-color: $color-alizarin;
    }
    & > .error-text{

      @include body-three;
      color: $color-quick-silver;
      padding-left: 10px;
      padding-top: 8px;
      color: $color-alizarin;
    }
  }
}
