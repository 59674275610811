.schedule-calender-container {
  border: 0.5px solid $color-gray-80;
  border-radius: 5px;
  width: fit-content;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  @include scrollbar;
  & > .calender-row {
    display: flex;
    flex-direction: row;
    & > .calender-cell {
      display: inline-flex;
      flex-direction: column;
      border-bottom: 0.5px solid $color-gray-80;
      gap: 2px;
      flex: 1 1 180px;
      min-width: 180px;
      padding: 10px 7px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-right: 0.5px solid $color-gray-80;
      &.deactivated{
        background-color: $color-mercury-gray;
      }
      &:first-of-type {
        display: flex;
        justify-content: center;
        padding: 10px 8px;
        min-width: 180px;

      }
      &:last-of-type {
        border-right: none;
      }
      .power-label {
        display: flex;
        align-items: center;
        margin-left: auto;
        color: $color-turquoise;
        padding: 2px 8px;
        background-color: $color-signal-white;
        border: 1px solid $color-turquoise;
        border-radius: 16px;
      }
    }
    &:last-of-type {
      & > .calender-cell {
        border-bottom: none;
      }
    }

    .deactivated-power{
    color: $color-quick-silver;
    }
  }
  .fs-12{
    font-size: 12px;
  }
  .muted-txt{
    color: $color-quick-silver
  }
  .color-power{
    color: $color-power;
  }
  .deactivated{
    background-color: $color-mercury-gray;
  }
  .calender-active{
    color: $color-black;
  }
}
