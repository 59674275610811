.display-flex.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.list{
  .list__header {

    background-color: $color-signal-white;
    height: 42px;
    border-radius: 5px 5px 0px 0px;
    padding: 18px 20px;
    margin-bottom: 10px;
    @include list-header;

    & > * {
      flex: 1 1 auto;
      width: 200px;
    }
  }

  .list__content {
    .list__row {
      border: 0.5px solid $color-gray-80;
      color: $color-dark-charcoal;
      border-radius: 5px;
      padding: 18px 20px;
      margin-bottom: 16px;
      @include h-2;
      
      &:last-of-type{
        margin-bottom: 0px;
      }

      & > * {
        flex: 1 1 auto;
        width: 200px;
      }
    }
  }
}